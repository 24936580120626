import React from "react"
import Root from "../components/root"
import { graphql } from "gatsby"
import { ThemeProvider } from "../components/darkThemeContext"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const More = ({ data, pageContext, location }) => {
  return (
    <>
      <GatsbySeo
        title={`Ricky Richards Photography - Page ${
          pageContext.currentPage + 1
        }`}
        description={`Ricky Richards Photography - Page ${
          pageContext.currentPage + 1
        }`}
        openGraph={{
          images: [
            {
              url: data.OGImage.nodes[0].image.resize.src,
              width: data.OGImage.nodes[0].image.resize.width,
              height: data.OGImage.nodes[0].image.resize.height,
              alt: data.OGImage.nodes[0].image.title,
            },
          ],
        }}
      />
      <ThemeProvider>
        <Root data={data} pageContext={pageContext} locationData={location} />
      </ThemeProvider>
    </>
  )
}

export default More

export const data = graphql`
  query($limit: Int!, $skip: Int!) {
    LargeImage: allContentfulJournal(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      nodes {
        title
        id
        body {
          raw
        }
        image {
          description
          title
          file {
            details {
              image {
                height
                width
              }
            }
          }
          fluid(quality: 90) {
            srcSet
            src
            srcSetWebp
            base64
          }
        }
      }
    }
    OGImage: allContentfulJournal(
      limit: 1
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        image {
          title
          resize(width: 1000, quality: 85) {
            src
            width
            height
          }
        }
      }
    }
  }
`
